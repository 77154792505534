import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import App from "App";

import axiosInstance from "config/axios";
import { initAmplitude } from "config/amplitude";
import "config/i18n/index";
import "config/sentry";

import theme from "utils/theme";

import { defaultOptions } from "utils/defaultOptions";
import { serviceOptions } from "./services/serviceOptions";

serviceOptions.axios = axiosInstance;

const queryClient = new QueryClient({
  defaultOptions,
});

initAmplitude();

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);
