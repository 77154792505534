import { useState } from "react";
import { useQuery } from "react-query";
import { Link as RLink } from "react-router-dom";

import {
  Container,
  Flex,
  Box,
  Spacer,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  BoxProps,
  Text,
} from "@chakra-ui/react";
import { HamburgerIcon, ChevronDownIcon } from "@chakra-ui/icons";

import RoutePath from "routes/path";
import {
  checkToken,
  clearToken,
  getLocalStorage,
  setLocalStorage,
} from "utils/token";

import {
  setAmplitudeUserId,
  setAmplitudeUserProperties,
  sendAmplitudeData,
  removeAmplitudeUserId,
} from "config/amplitude";

import { AuthService, getUserInfoResponse } from "services";

function Header(props: BoxProps): React.ReactElement {
  const [token, setToken] = useState<string | null>(null);
  const [profile, setProfile] = useState<getUserInfoResponse>({});

  useQuery("getProfileData", async () => {
    const tokenResult = checkToken();

    if (tokenResult) {
      setToken(tokenResult);

      const profileResult = getLocalStorage("profile");

      if (profileResult) {
        setProfile(JSON.parse(profileResult));
      } else {
        const data: getUserInfoResponse = await AuthService.getUserInfoRoute();

        if (data) {
          const userProperties = {
            email: data.email,
            app_version: process.env.REACT_APP_VERSION,
            user_access: "Tenant",
            is_active: null,
            referrer_id: null,
            is_subscribed: null,
            companies_name: null,
            companies_size: null,
            role_in_company: data.roles,
            job_status: null,
            city: null,
          };
          if (data.sub) setAmplitudeUserId(data.sub);
          setAmplitudeUserProperties(userProperties);

          setLocalStorage("profile", JSON.stringify(data));
          setProfile(data);
        }
      }
    }
  });

  const handleLogout = () => {
    sendAmplitudeData("Logout clicked", true);
    removeAmplitudeUserId();

    setTimeout(() => {
      clearToken();
      window.location.href = RoutePath.HOME;
    }, 1000);
  };

  return (
    <Box className="o-header" bgColor="knods.black.300" {...props}>
      <Container maxW="container.xl">
        <Box className="o-header__content" h={50}>
          <Flex alignItems="center" h="100%">
            <Link
              className="o-header__content-logo"
              as={RLink}
              to={token ? RoutePath.PRODUCT_LIST : RoutePath.HOME}
            >
              <Image
                src="/img/logo-dark.png"
                alt="logo"
                htmlWidth={714}
                htmlHeight={169}
                w={120}
                h="auto"
              />
            </Link>
            <Box className="o-header__content-action-left" mx={8}>
              <Link
                d={{ base: "none", md: "inline-block" }}
                mr={4}
                fontSize="sm"
                color="knods.gray.200"
                _hover={{ color: "white" }}
                href="https://handbook.labtekdev.site/"
                onClick={() => sendAmplitudeData("Handbook clicked", true)}
                isExternal
              >
                Handbook
              </Link>
              <Link
                d={{ base: "none", md: "inline-block" }}
                fontSize="sm"
                mr={4}
                color="knods.gray.200"
                _hover={{ color: "white" }}
                href="https://knods.slaask.help/hc/"
                onClick={() =>
                  sendAmplitudeData("Knowledge Based clicked", true)
                }
                isExternal
              >
                Knowledge Base
              </Link>
              {token && (
                <Link
                  d={{ base: "none", md: "inline-block" }}
                  mr={4}
                  fontSize="sm"
                  color="knods.gray.200"
                  _hover={{ color: "white" }}
                  href={`${process.env.REACT_APP_SHORTLIST_URL}?token=${token}`}
                  onClick={() => sendAmplitudeData("Shortlist clicked", true)}
                  isExternal
                >
                  Knods Shortlist
                </Link>
              )}
            </Box>
            <Spacer />
            <Box className="o-header__content-action-right">
              <Box
                d={{ base: "inline-block", md: "none" }}
                className="o-header__content-action-right__mobile"
              >
                <Menu>
                  <MenuButton
                    as={IconButton}
                    icon={<HamburgerIcon />}
                    variant="solid"
                    bgColor="knods.black.300"
                    color="white"
                    _hover={{
                      bgColor: "knods.black.200",
                      color: "white",
                    }}
                    _active={{
                      bgColor: "knods.black.200",
                      color: "white",
                    }}
                    _focus={{ boxShadow: "none" }}
                  />
                  <MenuList
                    mt={-1}
                    borderRadius="none"
                    borderColor="transparent"
                    bgColor="knods.black.300"
                    color="knods.gray.200"
                    w="100vw"
                  >
                    {!token && (
                      <>
                        <MenuItem
                          fontSize="sm"
                          _hover={{
                            bgColor: "knods.black.300",
                            color: "white",
                          }}
                          _focus={{
                            bgColor: "knods.black.300",
                          }}
                          as={RLink}
                          to={RoutePath.AUTH_LOGIN}
                        >
                          Sign in
                        </MenuItem>
                        <MenuItem
                          fontSize="sm"
                          _hover={{
                            bgColor: "knods.black.300",
                            color: "white",
                          }}
                          _focus={{
                            bgColor: "knods.black.300",
                          }}
                          as={RLink}
                          to={RoutePath.AUTH_REGISTER}
                        >
                          Register
                        </MenuItem>
                        <MenuItem
                          fontSize="sm"
                          _hover={{
                            bgColor: "knods.black.300",
                            color: "white",
                          }}
                          _focus={{
                            bgColor: "knods.black.300",
                          }}
                        >
                          <Link
                            href="https://handbook.labtekdev.site/"
                            onClick={() =>
                              sendAmplitudeData("Handbook clicked", true)
                            }
                            isExternal
                          >
                            Handbook
                          </Link>
                        </MenuItem>
                        <MenuItem
                          fontSize="sm"
                          _hover={{
                            bgColor: "knods.black.300",
                            color: "white",
                          }}
                          _focus={{
                            bgColor: "knods.black.300",
                          }}
                        >
                          <Link href="https://knods.slaask.help/hc/" isExternal>
                            Knowledge Base
                          </Link>
                        </MenuItem>
                      </>
                    )}
                    {token && (
                      <>
                        <MenuItem
                          fontSize="sm"
                          textColor="white"
                          _hover={{
                            bgColor: "knods.black.300",
                            color: "white",
                          }}
                          _focus={{
                            bgColor: "knods.black.300",
                          }}
                        >
                          {profile.callname}
                        </MenuItem>
                        <hr />
                        <MenuItem
                          fontSize="sm"
                          _hover={{
                            bgColor: "knods.black.300",
                            color: "white",
                          }}
                          _focus={{
                            bgColor: "knods.black.300",
                          }}
                          onClick={() => handleLogout()}
                        >
                          Logout
                        </MenuItem>
                      </>
                    )}
                  </MenuList>
                </Menu>
              </Box>
              {token && (
                <Box
                  d={{ base: "none", md: "inline-block" }}
                  className="o-header__content-action-right__desktop"
                >
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      variant="solid"
                      bgColor="knods.black.300"
                      color="white"
                      _hover={{
                        bgColor: "knods.black.200",
                        color: "white",
                      }}
                      _active={{
                        bgColor: "knods.black.200",
                        color: "white",
                      }}
                      _focus={{ boxShadow: "none" }}
                    >
                      <Flex>
                        <Text className="o-header__username" fontSize="sm">
                          {profile.callname}
                        </Text>
                        <ChevronDownIcon />
                      </Flex>
                    </MenuButton>
                    <MenuList borderRadius="none">
                      <MenuItem
                        fontSize="sm"
                        _hover={{
                          bgColor: "knods.gray.100",
                          color: "black",
                        }}
                        _focus={{
                          bgColor: "white",
                        }}
                        onClick={() => handleLogout()}
                      >
                        Logout
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Box>
              )}
              {!token && (
                <Box
                  d={{ base: "none", md: "inline-block" }}
                  className="o-header__content-action-right__desktop"
                >
                  <Link
                    mr={4}
                    fontSize="sm"
                    color="knods.gray.200"
                    _hover={{ color: "white" }}
                    as={RLink}
                    to={RoutePath.AUTH_LOGIN}
                  >
                    Sign in
                  </Link>
                  <Link
                    fontSize="sm"
                    color="knods.gray.200"
                    _hover={{ color: "white" }}
                    as={RLink}
                    to={RoutePath.AUTH_REGISTER}
                  >
                    Register
                  </Link>
                </Box>
              )}
            </Box>
          </Flex>
        </Box>
      </Container>
    </Box>
  );
}

export default Header;
