import { lazy } from "react";
import { IRoute } from "types/routes";
import RoutePath from "./path";

const routes: IRoute[] = [
  {
    path: RoutePath.HOME,
    Component: lazy(() => import("pages/Home")),
    name: "Home",
    isExact: true,
  },
  {
    path: RoutePath.AUTH_LOGIN,
    Component: lazy(() => import("pages/Auth/Login")),
    name: "AuthLogin",
    isExact: true,
  },
  {
    path: RoutePath.AUTH_REGISTER,
    Component: lazy(() => import("pages/Auth/Register")),
    name: "AuthRegister",
    isExact: true,
  },
  {
    path: RoutePath.AUTH_ACTIVATE,
    Component: lazy(() => import("pages/Auth/Activate")),
    name: "AuthActivate",
    isExact: true,
  },
  {
    path: RoutePath.AUTH_FORGOT_PASSWORD,
    Component: lazy(() => import("pages/Auth/ForgotPassword")),
    name: "AuthForgotPassword",
    isExact: true,
  },
  {
    path: RoutePath.AUTH_SET_NEW_PASSWORD,
    Component: lazy(() => import("pages/Auth/SetNewPassword")),
    name: "AuthSetNewtPassword",
    isExact: true,
  },
  {
    path: RoutePath.PRODUCT_LIST,
    name: "ProductList",
    Component: lazy(() => import("pages/Product/List")),
    isExact: true,
  },
  {
    path: RoutePath.PRODUCT_CREATE,
    name: "ProductCreate",
    Component: lazy(() => import("pages/Product/Create")),
    isExact: true,
  },
  {
    path: RoutePath.PRODUCT_UPDATE,
    name: "ProductUpdate",
    Component: lazy(() => import("pages/Product/Update")),
    isExact: true,
  },
  {
    path: RoutePath.PRODUCT_DETAIL,
    name: "ProductDetail",
    Component: lazy(() => import("pages/Module")),
    isExact: true,
  },
  {
    path: RoutePath.MODULE_CREATE,
    name: "Create Module",
    Component: lazy(() => import("pages/Module/Create")),
    isExact: true,
  },
  {
    path: RoutePath.MODULE_DETAIL,
    name: "Module Detail",
    Component: lazy(() => import("pages/Module/Detail")),
    isExact: true,
  },
  {
    path: RoutePath.TALENT_DETAIL,
    name: "Talent Detail",
    Component: lazy(() => import("pages/Talent/Detail")),
    isExact: true,
  },
  {
    path: RoutePath.SHORTLIST_LIST,
    name: "Knods Shortlist",
    Component: lazy(() => import("pages/Shortlist/index")),
    isExact: true,
  },
];

export default routes;
