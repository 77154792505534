import { extendTheme } from "@chakra-ui/react";

const theme = {
  colors: {
    knods: {
      black: {
        100: "#333333",
        200: "#222222",
        300: "#101010",
      },
      white: {
        100: "#FFFFFF",
        200: "#F5F5F5",
      },
      gray: {
        100: "#DDDDDD",
        200: "#9D9D9D",
      },
      text: {
        info: "#317093",
      },
    },
  },
};

export default extendTheme(theme);
