import { memo, Suspense } from "react";
import { Progress } from "@chakra-ui/react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Slaask from "slaask-react";

import { sendAmplitudeData } from "config/amplitude";

import routes from "routes";
import Header from "components/organisms/Header";

const slaaskKey = process.env.REACT_APP_SLAASK_KEY;

const Router = memo(() => (
  <Switch>
    <Suspense fallback={<Progress size="xs" isIndeterminate mt="50px" />}>
      {routes.map(({ path, isExact = false, Component }) => (
        <Route key={path} exact={isExact} path={path} component={Component} />
      ))}
    </Suspense>
  </Switch>
));

function App(): React.ReactElement {
  return (
    <BrowserRouter>
      <Slaask
        widgetKey={slaaskKey}
        onClick={() => sendAmplitudeData("Slaask clicked", true)}
      />
      <Header pos="fixed" top="0" w="100%" zIndex="9" />
      <Router />
    </BrowserRouter>
  );
}

export default App;
