import RoutePath from "routes/path";

const tokenName = process.env.REACT_APP_TOKEN_NAME || "token-knods";

/**
 * checkToken
 * this function for get token from local storage
 * @returns {string}
 */
export const checkToken = (): string | null => localStorage.getItem(tokenName);

/**
 * setToken
 * this function for set token from local storage
 * @param {string} token
 */
export const setToken = (token: string): void => {
  localStorage.setItem(tokenName, token);
};

/**
 * setLocalStorage
 * this function for set local storage data
 * @param {string} name
 * @param {string} value
 */
export const setLocalStorage = (name: string, value: string): void => {
  localStorage.setItem(name, value);
};

/**
 * getLocalStorage
 * this function for set local storage data
 * @param {string} name
 * @returns {string}
 */
export const getLocalStorage = (name: string): string | null =>
  localStorage.getItem(name);

/**
 * clearToken
 * this function for clear token from local storage
 */
export const clearToken = (): void => {
  localStorage.clear();
};

/**
 * notAuthorized
 * this function for auto logout when not authorized
 */
export const notAuthorized = (): void => {
  clearToken();
  window.location.href = RoutePath.HOME;
};
