/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

import { IRequestOptions, IRequestConfig, getConfigs, axios } from './serviceOptions';
import * as shortlistAxiosInstance from 'axios' ;
export const basePath = '/v1';
import qs from 'qs';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class CommonService {
  /**
   * Get healthy (liveness probe)
   */
  static getHealthyRoute(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/-/healthy';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get ready (readiness probe)
   */
  static getReadyRoute(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/-/ready';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AssetsService {
  /**
   *
   */
  static getAssetsContract(
    params: {
      /** Application */
      applicationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/assets/contract';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { application_id: params['applicationId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AuthService {
  /**
   *
   */
  static postActivateUser(
    params: {
      /**  */
      payload?: postActivateUserPayload;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/activate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Check password
   */
  static postCheckPassword(
    params: {
      /**  */
      payload?: postCheckPassword;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<defaultResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/check-password';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postForgotPassword(
    params: {
      /**  */
      payload?: postForgotPasswordPayload;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/forgot-password';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Login with local user
   */
  static postLoginRoute(
    params: {
      /**  */
      payload?: postLoginPayload;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<postLoginResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Get user info
   */
  static getUserInfoRoute(options: IRequestOptions = {}): Promise<getUserInfoResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/me';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Check referral code
   */
  static getCheckReferralCode(
    params: {
      /**  */
      code: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<defaultResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/referral/{code}';
      url = url.replace('{code}', params['code'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Register user
   */
  static postRegisterRoute(
    params: {
      /**  */
      payload?: postRegisterPayload;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<postRegisterResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/register';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postResendActivation(
    params: {
      /**  */
      payload?: postResendActivationPayload;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<defaultResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/resend-activation';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postResetPassword(
    params: {
      /**  */
      payload?: postResetPasswordPayload;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/reset-password';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update password
   */
  static putUpdatePassword(
    params: {
      /**  */
      payload?: putUpdatePassword;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<defaultResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/update-password';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TenantService {
  /**
   *
   */
  static postTenantForgotPassword(
    params: {
      /**  */
      payload?: postForgotPasswordPayload;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/tenant/forgot-password';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postTenantLoginRoute(
    params: {
      /**  */
      payload?: postLoginPayload;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<postLoginResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/tenant/login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postRegisterTenantRoute(
    params: {
      /**  */
      payload?: postRegisterTenantPayload;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<postRegisterResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/tenant/register';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postTenantResendActivation(
    params: {
      /**  */
      payload?: postResendActivationPayload;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<defaultResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/auth/tenant/resend-activation';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FeedbackService {
  /**
   *
   */
  static getFeedbackQuestions(options: IRequestOptions = {}): Promise<getFeedbackQuestionResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/feedback/questions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postPeerFeedback(
    params: {
      /**  */
      moduleId: string;
      /**  */
      payload?: postPeerFeedbackPayload;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<postPeerFeedbackResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/feedback/{module_id}/peer';
      url = url.replace('{module_id}', params['moduleId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postProjectFeedback(
    params: {
      /**  */
      moduleId: string;
      /**  */
      payload?: postProjectFeedbackPayload;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<postProjectFeedbackResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/feedback/{module_id}/project';
      url = url.replace('{module_id}', params['moduleId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postPeerRecommendation(
    params: {
      /**  */
      moduleId: string;
      /**  */
      payload?: postPeerRecommendationPayload;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<postPeerRecommendationResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/feedback/{module_id}/recommendation';
      url = url.replace('{module_id}', params['moduleId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFeedbackTalents(
    params: {
      /**  */
      moduleId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<getFeedbackTalentsResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/feedback/{module_id}/talents';
      url = url.replace('{module_id}', params['moduleId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ModulesService {
  /**
   *
   */
  static getListModule(
    params: {
      /**  */
      productId: string;
      /** The number of items to skip before starting to collect the result set */
      page?: number;
      /** The numbers of items to return */
      limit?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<getModulesResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/modules';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { productID: params['productId'], page: params['page'], limit: params['limit'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postCreateModule(
    params: {
      /**  */
      payload?: postCreateModulePayload;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<postCreateModuleResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/modules';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postAditionalCostModule(
    params: {
      /**  */
      payload?: postCreateModuleAdditionalCostsPayload;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/modules/additional-costs';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDetailModule(
    params: {
      /** Module ID */
      id: string;
      /** Product ID */
      productId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<getDetailModuleResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/modules/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { productID: params['productId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static putUpdateModule(
    params: {
      /** Module ID */
      id: string;
      /** Product ID */
      productId: string;
      /**  */
      payload?: putUpdateModulePayload;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<putUpdateModuleResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/modules/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { productID: params['productId'] };
      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getListAppliedTalent(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<getListAppliedTalentResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/modules/{id}/applied';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postInviteModule(
    params: {
      /** Module ID */
      id: string;
      /** List of Application ID */
      payload?: postInviteTalentPayload;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<defaultResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/modules/{id}/invite';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static putPaymentStrategy(
    params: {
      /**  */
      id: string;
      /** Payment Strategy Value */
      payload?: putPaymentStrategyPayload;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<defaultResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/modules/{id}/payment-strategy';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postPublishModule(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/modules/{id}/publish';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postStartModule(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/modules/{id}/start';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getListPublishModule(
    params: {
      /** The number of items to skip before starting to collect the result set */
      page?: number;
      /** The numbers of items to return */
      limit?: number;
      /** The filter of items to return */
      filter?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<getPublishModulesResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offerings';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { page: params['page'], limit: params['limit'], filter: params['filter'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ApplicationService {
  /**
   *
   */
  static applyJob(
    params: {
      /**  */
      payload?: postApplyModuleRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<postApplyModuleResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offering/apply';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getListApplyingModules(
    params: {
      /** The number of items to skip before starting to collect the result set */
      page?: number;
      /** The numbers of items to return */
      limit?: number;
      /** The filter of items to return */
      status?: string;
      /** The filter of items to return */
      moduleStatus?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<getApplyModuleResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offering/apply/lists';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        page: params['page'],
        limit: params['limit'],
        status: params['status'],
        module_status: params['moduleStatus']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getOfferingDetail(
    params: {
      /**  */
      applicationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<applyModule> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offering/{application_id}';
      url = url.replace('{application_id}', params['applicationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getApplicationConfirmation(
    params: {
      /** Application ID */
      applicationId: string;
      /** Ephemeral token that only valid for one time */
      token: string;
      /** Confirmation status to join or not to join */
      status: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<getApplicationConfirmationResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offering/{application_id}/confirmation';
      url = url.replace('{application_id}', params['applicationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { token: params['token'], status: params['status'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static putOfferingConfirmed(
    params: {
      /**  */
      moduleRoleId: string;
      /**  */
      payload?: putOfferingConfirmJoinRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<putOfferingConfirmJoinResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offering/{module_role_id}/confirmation/join';
      url = url.replace('{module_role_id}', params['moduleRoleId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static putOfferingNotJoin(
    params: {
      /**  */
      moduleRoleId: string;
      /**  */
      payload?: putOfferingConfirmNotJoinRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<putOfferingConfirmNotJoinResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offering/{module_role_id}/confirmation/not-join';
      url = url.replace('{module_role_id}', params['moduleRoleId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDetailJob(
    params: {
      /**  */
      moduleRoleId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<getDetailJobResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/offering/{module_role_id}/job-detail';
      url = url.replace('{module_role_id}', params['moduleRoleId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ShortlistService {
    /**
   *
   */
  static getListTalent(
    params: {
      page?: string;
      limit?: number;
      filter?: {
        roles?: string[] 
        sfia_level?: string,
        min_rate?: string,
        max_rate?: string,
      }
      orderBy?: string,
      orderDir?: string,
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<getListTalentResponse> {
    return new Promise((resolve, reject) => {
      let url = process.env.REACT_APP_SHORTLIST_API_URL + 'v1/talents';

      console.log('params', params)

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { 
        page: params['page'], 
        limit: params['limit'], 
        filter: {
          roles: params.filter?.roles,
          sfia_level: params.filter?.sfia_level,
          min_rate: params.filter?.min_rate,
          max_rate: params.filter?.max_rate,
        },
        orderBy: params.orderBy,
        orderDir: params.orderDir,
      };
      configs.paramsSerializer = (param) => {
        return qs.stringify(param)
      }
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProductService {
  /**
   *
   */
  static createProduct(
    params: {
      /**  */
      payload?: postCreateProductPayload;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<postCreateProductResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/product/tenant';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getListProduct(
    params: {
      /** The number of items to skip before starting to collect the result set */
      page?: string;
      /** The numbers of items to return */
      limit?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<getListProductResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/product/tenant/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { page: params['page'], limit: params['limit'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDetailProduct(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<getProductResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/product/tenant/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateProduct(
    params: {
      /**  */
      id: string;
      /**  */
      payload?: updateProductPayload;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<updateProductResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/product/tenant/{id}/update';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ReferralLogService {
  /**
   *
   */
  static getReferralLog(
    params: {
      /**  */
      page?: string;
      /**  */
      limit?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<getReferralLogResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/referral_log';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { page: params['page'], limit: params['limit'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TalentService {
  /**
   *
   */
  static putUserBusinessProfile(
    params: {
      /**  */
      payload?: putUserBusinessProfilePayload;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<putUserBusinessProfileResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/talents/business-profile';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static putUserProfile(
    params: {
      /**  */
      payload?: putUserProfilePayload;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<putUserProfileResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/talents/profile';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTalentHistory(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<getTalentHistoryResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/talents/{id}/history';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TeamService {
  /**
   *
   */
  static getTeamComposition(
    params: {
      /**  */
      productId: string;
      /**  */
      moduleId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<getTeamCompositionResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/teams/list/{product_id}/{module_id}';
      url = url.replace('{product_id}', params['productId'] + '');
      url = url.replace('{module_id}', params['moduleId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateTeamCompositon(
    params: {
      /**  */
      productId: string;
      /**  */
      moduleId: string;
      /**  */
      payload?: updateTeamCompositionPayload;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<updateTeamCompositionResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/teams/{product_id}/{module_id}/update';
      url = url.replace('{product_id}', params['productId'] + '');
      url = url.replace('{module_id}', params['moduleId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['payload'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TimelineService {
  /**
   *
   */
  static getTimelineByModuleId(
    params: {
      /**  */
      moduleId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<getTimelineByModuleIdResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/timeline/{moduleID}';
      url = url.replace('{moduleID}', params['moduleId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UploadService {
  /**
   *
   */
  static postUploadFile(
    params: {
      /** The uploaded file data */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<postUploadFileResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/upload';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface additionalCosts {
  /**  */
  cost_type?: string;

  /**  */
  description?: string;

  /**  */
  is_fixed?: boolean;

  /**  */
  name?: string;

  /**  */
  price?: number;
}

export interface additionalCostsSelected {
  /** Additional Cost Name */
  name?: string;

  /**  */
  price?: number;
}

export interface additionalTeam {
  /**  */
  est_work_day?: number;

  /**  */
  est_work_hour?: number;

  /**  */
  is_fixed_qty?: boolean;

  /**  */
  is_lead?: boolean;

  /**  */
  job_desc?: string;

  /**  */
  position?: string;

  /**  */
  position_code?: string;

  /**  */
  total_talent?: number;
}

export interface appliedTalent {
  /**  */
  accuracy_point?: number;

  /**  */
  application_id?: string;

  /**  */
  average_autonomy?: number;

  /**  */
  average_business_skill?: number;

  /**  */
  average_complexity?: number;

  /**  */
  average_influence_level?: number;

  /**  */
  average_knowledge?: number;

  /**  */
  cv?: string;

  /**  */
  day_rate?: number;

  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  platform_fee?: number;

  /**  */
  portfolio?: string;

  /**  */
  recommendation_rate?: number;

  /**  */
  role_id?: string;

  /**  */
  sfia?: number;

  /**  */
  status?: string;
}

export interface applyModule {
  /** ID of Application */
  application_id?: string;

  /** Brief of current module */
  brief?: string;

  /** Time when module is closed by admin */
  closed_date?: Date;

  /** Time when module offer is closed */
  closed_offer_date?: Date;

  /**  */
  code?: moduleCode;

  /**  */
  company_name?: string;

  /** Date creation */
  created_at?: Date;

  /** Desired module delivarables based on Module Type */
  deliverables?: moduleDeliverables[];

  /** End date and time for the module */
  end_date?: Date;

  /**  */
  honor?: object;

  /** ID of Module */
  id?: string;

  /** Ignore module */
  ignore?: boolean;

  /** Location of the module conducted */
  location?: string;

  /** Incremental module number based on product_id */
  m_order?: any | null;

  /**  */
  module_role_id?: string;

  /** Time when module is postponed by system */
  postponed_date?: Date;

  /**  */
  product_code?: string;

  /**  */
  product_description?: string;

  /** ID of related product */
  product_id?: string;

  /**  */
  product_name?: string;

  /**  */
  role_applying?: string;

  /**  */
  role_id?: string;

  /** Incremental product module based on product id */
  rv_number?: string;

  /** Start date and time for the module */
  start_date?: Date;

  /** Time when module is started by client */
  started_date?: Date;

  /**  */
  status?: moduleStatus;

  /**  */
  status_applying?: string;

  /** Success rate of this Module */
  success_rate?: number;

  /** Module */
  timeline?: timeline[];

  /** Total respondents for In-depth Interview module */
  total_respondent?: number;

  /** Date update */
  updated_at?: Date;

  /** Waiting for project's feedback status for particular user */
  waiting_feedback?: boolean;
}

export interface company {
  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  role_in_company?: string;

  /**  */
  size?: string;

  /**  */
  user_id?: string;
}

export interface defaultResponse {
  /** Response data */
  data?: object;

  /** Response message if any */
  message?: string;
}

export interface getApplicationConfirmationResponse {
  /**  */
  message?: EnumgetApplicationConfirmationResponseMessage;
}

export interface getApplyModuleResponse {
  /**  */
  data?: applyModule[];

  /**  */
  limit?: number;

  /**  */
  page?: number;

  /**  */
  total_data?: number;

  /**  */
  total_page?: number;
}

export interface getDetailJobResponse {
  /**  */
  data?: moduleRole;
}

export interface getDetailModuleResponse {
  /**  */
  data?: module;
}

export interface getFeedbackQuestionResponse {
  /**  */
  data?: question[];
}

export interface getFeedbackTalentsResponse {
  /**  */
  data?: talent[];
}

export interface getListAppliedTalentResponse {
  /**  */
  data?: listAppliedTalent;
}

export interface getListProductResponse {
  /**  */
  data?: product[];

  /**  */
  limit?: number;

  /**  */
  page?: number;

  /**  */
  total_data?: number;

  /**  */
  total_page?: number;
}

export interface getListTalentResponse {
  /**  */
  data?: talent[];

  /**  */
  limit?: number;

  /**  */
  page?: number;

  /**  */
  total_data?: number;

  /**  */
  total_page?: number;
}

export interface getModulesResponse {
  /**  */
  data?: module[];

  /**  */
  limit?: number;

  /**  */
  page?: number;

  /**  */
  total_data?: number;

  /**  */
  total_page?: number;
}

export interface getProductResponse {
  /**  */
  data?: product;
}

export interface getPublishModulesResponse {
  /**  */
  data?: publishModule[];

  /**  */
  limit?: number;

  /**  */
  page?: number;

  /**  */
  total_data?: number;

  /**  */
  total_page?: number;
}

export interface getReferralLogResponse {
  /**  */
  data?: referralLog[];

  /**  */
  limit?: number;

  /**  */
  page?: number;

  /**  */
  total_data?: number;

  /**  */
  total_page?: number;
}

export interface getTalentHistoryResponse {
  /**  */
  data?: talentHistory;
}

export interface getTeamCompositionResponse {
  /**  */
  data?: object;
}

export interface getTimelineByModuleIdResponse {
  /**  */
  data?: timeline[];
}

export interface getUserInfoResponse {
  /**  */
  accuracy_point?: number;

  /**  */
  bank?: object;

  /**  */
  callname?: string;

  /**  */
  city?: string;

  /**  */
  date_of_birth?: any | null;

  /**  */
  docs?: object;

  /** Email address of user */
  email?: string;

  /**  */
  fullname?: string;

  /** Determine if a particular user still has waiting feedback */
  has_waiting_feedback?: boolean;

  /**  */
  identity?: object;

  /** Job status (Freelancer / Moonlighter) */
  job_status?: string;

  /**  */
  performance?: object;

  /**  */
  phone_number?: string;

  /**  */
  recommendation_rate?: number;

  /**  */
  reffereral_code?: string;

  /**  */
  roles?: string[];

  /**  */
  sfia?: number;

  /** ID of User */
  sub?: string;

  /** Determine if a particular user still has waiting feedback on which module id */
  waiting_feedback_module?: string;
}

export interface httpValidationErrorDetail {
  /** Error describing field validation failure */
  error?: string;

  /** Indicates how the invalid field was provided */
  in?: string;

  /** Key of field failing validation */
  key?: string;
}

export interface listAppliedTalent {
  /**  */
  additional_costs?: additionalCosts[];

  /**  */
  additional_costs_selected?: additionalCostsSelected[];

  /**  */
  payment_strategy?: string;

  /**  */
  quotation_url?: string;

  /**  */
  talents?: appliedTalent[];
}

export interface mainTeam {
  /**  */
  est_work_day?: number;

  /**  */
  est_work_hour?: number;

  /**  */
  id?: string;

  /**  */
  is_fixed_qty?: boolean;

  /**  */
  is_lead?: boolean;

  /**  */
  job_desc?: string;

  /**  */
  position?: string;

  /**  */
  position_code?: string;

  /**  */
  total_talent?: number;
}

export interface module {
  /** Brief of current module */
  brief?: string;

  /** Time when module is closed by admin */
  closed_date?: Date;

  /** Time when module offer is closed */
  closed_offer_date?: Date;

  /**  */
  code?: moduleCode;

  /** Date creation */
  created_at?: Date;

  /** Desired module delivarables based on Module Type */
  deliverables?: moduleDeliverables[];

  /** End date and time for the module */
  end_date?: Date;

  /** ID of Module */
  id?: string;

  /** Ignore module */
  ignore?: boolean;

  /** Location of the module conducted */
  location?: string;

  /** Incremental module number based on product_id */
  m_order?: any | null;

  /** Time when module is postponed by system */
  postponed_date?: Date;

  /** ID of related product */
  product_id?: string;

  /** Incremental product module based on product id */
  rv_number?: string;

  /** Start date and time for the module */
  start_date?: Date;

  /** Time when module is started by client */
  started_date?: Date;

  /**  */
  status?: moduleStatus;

  /** Success rate of this Module */
  success_rate?: number;

  /** Total respondents for In-depth Interview module */
  total_responden?: number;

  /** Date update */
  updated_at?: Date;
}

export interface moduleRole {
  /**  */
  est_work_day?: number;

  /**  */
  est_work_hour?: number;

  /**  */
  id?: string;

  /**  */
  is_fixed_quantity?: boolean;

  /**  */
  is_lead?: boolean;

  /**  */
  module_id?: string;

  /**  */
  name?: string;

  /**  */
  nice_to_have_requirement?: string;

  /**  */
  quantity?: number;

  /**  */
  requirements?: string;

  /**  */
  role_id?: EnummoduleRoleRoleId;

  /**  */
  specific_role?: string;

  /**  */
  status_applied?: object;
}

export interface openingPositions {
  /** Date creation */
  created_at?: Date;

  /**  */
  duration?: string;

  /**  */
  id?: string;

  /**  */
  is_fixed_quantity?: boolean;

  /**  */
  is_lead?: boolean;

  /**  */
  module_id?: string;

  /**  */
  name?: string;

  /**  */
  nice_to_have_requirements?: string;

  /**  */
  quantity?: number;

  /**  */
  requirements?: string;

  /**  */
  role_id?: string;

  /**  */
  specific_role?: string;

  /**  */
  status_applied?: object;

  /** Date update */
  updated_at?: Date;
}

export interface postActivateUserPayload {
  /** Token */
  token?: string;
}

export interface postApplyModuleRequest {
  /**  */
  day_rate?: number;

  /**  */
  module_role_id?: string;
}

export interface postApplyModuleResponse {
  /**  */
  message?: string;
}

export interface postCheckPassword {
  /**  */
  password?: string;
}

export interface postCreateModuleAdditionalCosts {
  /** Additional Cost Name */
  name?: string;

  /**  */
  price?: number;
}

export interface postCreateModuleAdditionalCostsPayload {
  /**  */
  data?: postCreateModuleAdditionalCosts[];

  /** Module ID */
  module_id?: string;
}

export interface postCreateModulePayload {
  /** Module brief */
  brief?: string;

  /**  */
  code?: moduleCode;

  /** Desired module output based on Module Type */
  deliverables?: moduleDeliverables[];

  /** Location where module will be conducted */
  location?: string;

  /** Product ID */
  product_id?: string;

  /** Start date and time for the module */
  start_date?: Date;

  /** Desired total response */
  total_responden?: number;
}

export interface postCreateModuleResponse {
  /**  */
  data?: module;
}

export interface postCreateProductPayload {
  /**  */
  description?: string;

  /**  */
  name?: string;
}

export interface postCreateProductResponse {
  /**  */
  code?: string;

  /**  */
  created_at?: Date;

  /**  */
  description?: string;

  /**  */
  id?: string;

  /**  */
  name?: string;
}

export interface postForgotPasswordPayload {
  /** Email address of user */
  email?: string;
}

export interface postLoginPayload {
  /** Identity of user to authenticate as */
  email?: string;

  /** Password of user to authenticate as */
  password?: string;
}

export interface postLoginResponse {
  /** Access token required for accessing protected API endpoints */
  access_token?: string;

  /** Access token expiry in seconds */
  expires_in?: number;

  /** Refresh token for refreshing the access token once it expires */
  refresh_token?: string;

  /** Type of access token, will always be `bearer` */
  token_type?: string;
}

export interface postPeerFeedbackPayload {
  /** Peer feedback from reviewer. It consists of array of question detail. */
  feedback?: question[];

  /** User ID of the reviewee */
  reviewee_id?: string;
}

export interface postPeerFeedbackResponse {
  /**  */
  data?: postPeerFeedbackPayload;
}

export interface postPeerRecommendationPayload {
  /** Reason giving feedback */
  reason?: string;

  /** User ID of the reviewee */
  reviewee_id?: string;

  /** Integer value of the feedback. Range from 1 to 10. */
  value?: number;
}

export interface postPeerRecommendationResponse {
  /**  */
  data?: postPeerRecommendationPayload;
}

export interface postProjectFeedbackPayload {
  /** Reason giving feedback */
  reason?: string;

  /** Integer value of the feedback. Range from 1 to 10. */
  value?: number;
}

export interface postProjectFeedbackResponse {
  /**  */
  data?: postProjectFeedbackPayload;
}

export interface postRegisterPayload {
  /**  */
  callName?: string;

  /**  */
  date_of_birth?: Date;

  /**  */
  email?: string;

  /**  */
  fullname?: string;

  /**  */
  password?: string;

  /**  */
  phone_number?: string;

  /**  */
  referrer_code?: string;

  /**  */
  referrer_relationship?: string;
}

export interface postRegisterResponse {
  /**  */
  message?: string;
}

export interface postRegisterTenantPayload {
  /**  */
  callname?: string;

  /**  */
  company_name?: string;

  /**  */
  company_size?: EnumpostRegisterTenantPayloadCompanySize;

  /**  */
  email?: string;

  /**  */
  fullname?: string;

  /**  */
  password?: string;

  /**  */
  phone_number?: string;

  /**  */
  role_in_company?: string;
}

export interface postResendActivationPayload {
  /** Email address of user */
  email?: string;
}

export interface postResetPasswordPayload {
  /**  */
  new_password?: string;

  /**  */
  new_password_confirmation?: string;

  /** Token */
  token?: string;
}

export interface postUploadFile {
  /** Response upload file */
  url?: string;
}

export interface postUploadFileResponse {
  /**  */
  data?: postUploadFile;
}

export interface talent {
  fullname?: string;
  u_identity?: string;
  average_day_rate?: number,
  accuracy_point?: number;
  recommendation_rate?: number;
  sfia_level?: number;
  autonomy_level?: number;
  influence_level?: number;
  complexity_level?: number;
  knowledge_level?: number;
  business_level?: number;
}

export interface product {
  /** Product code, auto generate by system */
  code?: string;

  /** Product created timestamp */
  created_at?: Date;

  /** Product description */
  description?: string;

  /**  */
  id?: string;

  /** Product name */
  name?: string;

  /** Product updated timestamp */
  updated_at?: Date;
}

export interface publicHttpError {
  /** More detailed, human-readable, optional explanation of the error */
  detail?: string;

  /** HTTP status code returned for the error */
  status?: number;

  /** Short, human-readable description of the error */
  title?: string;

  /** Type of error returned, should be used for client-side error handling */
  type?: string;
}

export interface publicHttpValidationError {
  /** List of errors received while validating payload against schema */
  validationErrors?: httpValidationErrorDetail[];
}

export interface publishModule {
  /**  */
  company?: company;

  /**  */
  module?: module;

  /** Opening Positions */
  opening_positions?: openingPositions[];

  /**  */
  product?: product;

  /** Module */
  timeline?: timeline[];
}

export interface putOfferingConfirmJoinRequest {
  /**  */
  application_id?: string;
}

export interface putOfferingConfirmJoinResponse {
  /**  */
  message?: EnumputOfferingConfirmJoinResponseMessage;
}

export interface putOfferingConfirmNotJoinRequest {
  /**  */
  application_id?: string;

  /**  */
  remarks?: string;
}

export interface putOfferingConfirmNotJoinResponse {
  /**  */
  message?: EnumputOfferingConfirmNotJoinResponseMessage;

  /**  */
  remarks?: string;
}

export interface putPaymentStrategyPayload {
  /**  */
  payment_strategy?: EnumputPaymentStrategyPayloadPaymentStrategy;
}

export interface putUpdateModulePayload {
  /** Module brief */
  brief?: string;

  /** Location of the module conducted */
  location?: string;

  /** Start date and time for the module */
  start_date?: Date;

  /** Desired total response */
  total_responden?: number;
}

export interface putUpdateModuleResponse {
  /**  */
  data?: module;
}

export interface putUpdatePassword {
  /**  */
  password_new?: string;

  /**  */
  password_old?: string;
}

export interface putUserBusinessProfile {
  /** City */
  city?: string;

  /** Response upload file */
  cv?: string;

  /** Job status (Freelancer / Moonlighter) */
  job_status?: string;

  /** Linkedin profile */
  linkedin?: string;

  /** Response upload file */
  portfolio?: string;

  /** Roles code */
  roles?: string[];
}

export interface putUserBusinessProfilePayload {}

export interface putUserBusinessProfileResponse {
  /**  */
  data?: putUserBusinessProfile;
}

export interface putUserProfile {
  /**  */
  bank_account_name?: string;

  /**  */
  bank_account_number?: string;

  /**  */
  bank_name?: string;

  /**  */
  callname?: string;

  /**  */
  city?: string;

  /**  */
  cv?: string;

  /**  */
  date_of_birth?: any | null;

  /**  */
  fullname?: string;

  /** Job status (Freelancer / Moonlighter) */
  job_status?: string;

  /**  */
  ktp_number?: string;

  /**  */
  ktp_pic?: string;

  /**  */
  linkedin?: string;

  /**  */
  npwp_number?: string;

  /**  */
  npwp_pic?: string;

  /**  */
  phone_number?: string;

  /**  */
  portfolio?: string;

  /**  */
  roles?: string[];
}

export interface putUserProfilePayload {}

export interface putUserProfileResponse {
  /**  */
  data?: putUserProfile;
}

export interface question {
  /** Question ID */
  id?: string;

  /** Question text */
  question?: string;

  /** Type of the question */
  question_type?: EnumquestionQuestionType;

  /** Value of the question */
  value?: number;
}

export interface referralLog {
  /**  */
  amount?: number;

  /**  */
  created_at?: string;

  /**  */
  id?: string;

  /**  */
  module_role?: string;

  /**  */
  project_id?: string;

  /**  */
  referral_email?: string;

  /**  */
  referral_name?: string;

  /**  */
  status?: EnumreferralLogStatus;
}

export interface talent {
  /**  */
  email?: string;

  /** User ID of talent */
  id?: string;

  /** Talent fullname */
  name?: string;

  /**  */
  peer_review?: object;

  /**  */
  role?: object;
}

export interface talentHistory {
  /**  */
  accuracy_point?: number;

  /**  */
  autonomy_level?: number;

  /**  */
  business_level?: number;

  /**  */
  call_name?: string;

  /**  */
  complexity_level?: number;

  /**  */
  cv?: string;

  /**  */
  email?: string;

  /**  */
  id?: string;

  /**  */
  influence_level?: number;

  /**  */
  knowledge_level?: number;

  /**  */
  name?: string;

  /**  */
  phone_number?: string;

  /**  */
  portfolio?: string;

  /**  */
  project_histories?: any | null[];

  /**  */
  recommendation_rate?: number;

  /**  */
  refferer_name?: string;

  /**  */
  sfia?: number;
}

export interface timeline {
  /** Date creation */
  created_at?: Date;

  /** Time when specific timeline ended */
  end_date?: Date;

  /** ID of a timeline */
  id?: string;

  /** ID of related module */
  module_id?: string;

  /** Timeline remarks */
  remarks?: string;

  /** Time when specific timeline started */
  start_date?: Date;

  /** Order of the timeline. It used for ordering the timeline. */
  t_order?: number;

  /**  */
  timeline_id?: timelineCode;

  /** Date update */
  updated_at?: Date;
}

export interface updateProductPayload {
  /**  */
  description?: string;

  /**  */
  name?: string;
}

export interface updateProductResponse {
  /**  */
  code?: string;

  /**  */
  description?: string;

  /**  */
  id?: string;

  /**  */
  name?: string;

  /**  */
  updated_at?: Date;
}

export interface updateTeamCompositionPayload {
  /**  */
  data?: object[];
}

export interface updateTeamCompositionResponse {
  /**  */
  data?: object;
}
export enum EnumgetApplicationConfirmationResponseMessage {
  'CONFIRM_JOIN' = 'CONFIRM_JOIN',
  'NOT_CONFIRM_JOIN' = 'NOT_CONFIRM_JOIN'
}
export enum moduleCode {
  'IDI' = 'IDI',
  'CC' = 'CC',
  'DS' = 'DS',
  'SS' = 'SS',
  'TS' = 'TS',
  'TR' = 'TR'
}

export enum moduleDeliverables {
  'CI' = 'CI',
  'DE' = 'DE',
  'FS' = 'FS',
  'DC' = 'DC',
  'PB' = 'PB',
  'UP' = 'UP',
  'CJ' = 'CJ',
  'XCJ' = 'XCJ',
  'LFP' = 'LFP'
}
export enum EnummoduleRoleRoleId {
  'R-LDR' = 'R-LDR',
  'R-AR' = 'R-AR',
  'R-LF' = 'R-LF',
  'R-CF' = 'R-CF',
  'R-UX' = 'R-UX',
  'R-UI' = 'R-UI',
  'R-PO' = 'R-PO',
  'R-FP' = 'R-FP',
  'R-FEP' = 'R-FEP',
  'R-BEP' = 'R-BEP',
  'R-SM' = 'R-SM',
  'R-ST' = 'R-ST',
  'R-LP' = 'R-LP',
  'R-TR' = 'R-TR'
}

export const rolesArray = [
  {
    code: "R-LDR",
    name:"Lead Design Researcher"
  },
  {
    code: "R-AR",
    name:"Assistant Researcher"
  },
  {
    code: "R-LF",
    name:"Lead Facilitator"
  },
  {
    code: "R-CF",
    name:"Co-Facilitator"
  },
  {
    code: "R-UX",
    name:"UX Designer"
  },
  {
    code: "R-UI",
    name:"UI Designer"
  },
  {
    code: "R-PO",
    name:"Product Owner"
  },
  {
    code: "R-FP",
    name:"Fullstack Programmer"
  },
  {
    code: "R-FEP",
    name:"Front-End Programmer"
  },
  {
    code: "R-BEP",
    name:"Back-End Programmer"
  },
  {
    code: "R-SM",
    name:"Scrum Master"
  },
  {
    code: "R-ST",
    name:"Software Tester"
  },
  {
    code: "R-LP",
    name:"Lead Programmer"
  },
  {
    code: "R-TR",
    name:"Lead Trainer"
  }
]

export enum EnumModuleRoleRoleName {
    "R-LDR" = "Lead Design Researcher",
    "R-AR" = "Assistant Researcher",
    "R-LF" = "Lead Facilitator",
    "R-CF" = "Co-Facilitator",
    "R-UX" = "UX Designer",
    "R-UI" = "UI Designer",
    "R-PO" = "Product Owner",
    "R-FP" = "Fullstack Programmer",
    "R-FEP" = "Front-End Programmer",
    "R-BEP" = "Back-End Programmer",
    "R-SM" = "Scrum Master",
    "R-ST" = "Software Tester",
    "R-LP" = "Lead Programmer",
    "R-TR" = "Lead Trainer",
}

export enum moduleStatus {
  'DRAFT' = 'DRAFT',
  'SCHEDULED' = 'SCHEDULED',
  'PUBLISHED' = 'PUBLISHED',
  'POSTPONED' = 'POSTPONED',
  'ONGOING' = 'ONGOING',
  'CLOSED' = 'CLOSED',
  'ARCHIVED' = 'ARCHIVED',
  'SCHEDULED_TO_CLOSED' = 'SCHEDULED_TO_CLOSED'
}
export enum EnumpostRegisterTenantPayloadCompanySize {
  'micro' = 'micro',
  'small' = 'small',
  'medium' = 'medium',
  'enterprise' = 'enterprise'
}
export enum EnumputOfferingConfirmJoinResponseMessage {
  'KEY_' = '',
  'INVITED' = 'INVITED',
  'CONFIRM_JOIN' = 'CONFIRM_JOIN',
  'NOT_CONFIRM' = 'NOT_CONFIRM',
  'NOT_CONFIRM_JOIN' = 'NOT_CONFIRM_JOIN',
  'NOT_AVAILABLE' = 'NOT_AVAILABLE'
}
export enum EnumputOfferingConfirmNotJoinResponseMessage {
  'KEY_' = '',
  'INVITED' = 'INVITED',
  'CONFIRM_JOIN' = 'CONFIRM_JOIN',
  'NOT_CONFIRM' = 'NOT_CONFIRM',
  'NOT_CONFIRM_JOIN' = 'NOT_CONFIRM_JOIN',
  'NOT_AVAILABLE' = 'NOT_AVAILABLE'
}
export enum EnumputPaymentStrategyPayloadPaymentStrategy {
  '100 :diawal' = '100 :diawal',
  '100 :diakhir' = '100 :diakhir',
  '30 :diawal 70 :diakhir' = '30 :diawal 70 :diakhir'
}
export enum EnumquestionQuestionType {
  'AUTONOMY' = 'AUTONOMY',
  'KNOWLEDGE' = 'KNOWLEDGE',
  'BUSINESS' = 'BUSINESS',
  'COMPLEXITY' = 'COMPLEXITY',
  'INFLUENCE' = 'INFLUENCE'
}
export enum EnumreferralLogStatus {
  'PENDING' = 'PENDING',
  'PAID' = 'PAID'
}
export enum timelineCode {
  'T-KM' = 'T-KM',
  'T-CM' = 'T-CM',
  'T-CCW' = 'T-CCW',
  'T-IGD' = 'T-IGD',
  'T-IGC' = 'T-IGC',
  'T-RS' = 'T-RS',
  'T-IS' = 'T-IS',
  'T-DO' = 'T-DO',
  'T-CI' = 'T-CI',
  'T-RC' = 'T-RC',
  'T-CR' = 'T-CR',
  'T-CP' = 'T-CP',
  'T-SPM' = 'T-SPM',
  'T-DS' = 'T-DS',
  'T-SRM' = 'T-SRM',
  'T-TRS' = 'T-TRS',
  'T-TSD' = 'T-TSD',
  'T-TSC' = 'T-TSC',
  'T-TS' = 'T-TS'
}
