interface IRoutePath {
  [key: string]: string;
}

const prefixPath: IRoutePath = {
  AUTH: "/auth",
  MODULE: "/module",
  PRODUCT: "/product",
  TALENT: "/talent",
  SHORTLIST: "/shortlist",
};

const RoutePath: IRoutePath = {
  HOME: "/",
  AUTH_ACTIVATE: "/activate",
  AUTH_SET_NEW_PASSWORD: "/reset-password",
  AUTH_LOGIN: `${prefixPath.AUTH}/login`,
  AUTH_REGISTER: `${prefixPath.AUTH}/register`,
  AUTH_FORGOT_PASSWORD: `${prefixPath.AUTH}/forgot-password`,
  PRODUCT_LIST: `${prefixPath.PRODUCT}/`,
  PRODUCT_CREATE: `${prefixPath.PRODUCT}/create`,
  PRODUCT_DETAIL: `${prefixPath.PRODUCT}/detail/:id`,
  PRODUCT_UPDATE: `${prefixPath.PRODUCT}/update/:id`,
  MODULE_CREATE: `${prefixPath.MODULE}/create`,
  MODULE_DETAIL: `${prefixPath.MODULE}/:id`,
  TALENT_DETAIL: `${prefixPath.TALENT}/:id/:name`,
  SHORTLIST_LIST: `${prefixPath.SHORTLIST}/`,
};

export default RoutePath;
