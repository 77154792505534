import axios, { AxiosError, AxiosResponse } from "axios";

import { checkToken, notAuthorized } from "utils/token";

const SHORTLIST_OPTIONS = {
  baseURL: process.env.REACT_APP_SHORTLIST_API_URL,
  timeout: 20000,
  headers: {
    "Content-type": "application/json",
  },
};

const OPTIONS = {
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 20000,
  headers: {
    "Content-type": "application/json",
  },
};

const axiosInstance = axios.create(OPTIONS);
export const shortlistAxiosInstance = axios.create(SHORTLIST_OPTIONS);

axiosInstance.interceptors.request.use((config) => {
  const newConfig = config;
  const token = checkToken();

  if (token) newConfig.headers.Authorization = `Bearer ${token}`;

  return newConfig;
});

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    const { response } = error;

    if (response?.status === 401) {
      notAuthorized();
    }

    if (
      response?.status === 400 &&
      response?.data.message === "missing or malformed jwt"
    ) {
      notAuthorized();
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
