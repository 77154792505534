import amplitude from "amplitude-js";

export const initAmplitude = () => {
  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY);
};

export const setAmplitudeUserDevice = (installationToken: unknown) => {
  amplitude.getInstance().setDeviceId(installationToken);
};

export const setAmplitudeUserId = (userId: string) => {
  if (userId) amplitude.getInstance().setUserId(userId);
};

export const removeAmplitudeUserId = () => {
  amplitude.getInstance().setUserId(null);
  amplitude.getInstance().regenerateDeviceId();
};

export const setAmplitudeUserProperties = (properties: unknown) => {
  amplitude.getInstance().setUserProperties(properties);
};

export const sendAmplitudeData = (
  eventType: unknown,
  eventProperties: unknown,
) => {
  amplitude.getInstance().logEvent(eventType, eventProperties);
};
